import { Box, FormLabel, Stack, Text } from '@chakra-ui/react';
import React, { MutableRefObject } from 'react';
import Select, {
  ActionMeta,
  components,
  GroupBase,
  Options,
  PlaceholderProps,
  SelectInstance,
  StylesConfig,
} from 'react-select';

import { DEFAULT_GAP_VALUE, DEFAULT_Z_INDEX, DEFAULT_ZERO } from '@/constants/defaults';
import { useAppSelector } from '@/store/hooks';
import { RootState } from '@/store/store';
import { DropdownAction } from '@/types/ui.types';

export interface FilterSelectValue {
  value: number;
  label: string;
}

interface FilterSelectProps {
  /** Determines if this component will allow all items to be selected. If set to true, a select all option will be displayed at the top of the options list. Selecting all options while the options list is filtered will select or deselect all filtered options */
  hasSelectAll: boolean;

  /** A string assigned to the HTML Input element.
   Without this value, the input will not render to the DOM */
  inputName: string;

  /** Specifies if this component will allow multiple options to be selected */
  isMultiSelect: boolean;
  
  /** If true, disabled the Select.
   It is used to disable deselcting if the template dropdown has only one value selected */
  isSingleTemplateSelected?: boolean;

  /** Accepts the values selected by the user and then takes some action on them
   *  @param {unknown} values - The selected values
   *  @param {ActionMeta<unknown>} actionMeta - The properties of the action taken
   * */
  onChangeHandler: (values: unknown, action?: DropdownAction) => void;

  /** An array of options to display in the custom-dropdown.
   * @param {any} value - The value of the option, used internally for logic
   * @param {string} label - The text value representing an option in the custom-dropdown
   * */
  options: object[];

  optionValueKey: string;

  optionLabelKey: string;
  /** The default text value displayed in the text input */
  placeHolderText: string;

  /** Array of values to compare against currently selected values. Think of it as the prevProps equivalent of selected values. This is used for selection comparison inside React-Select onChange event handler */
  selectedValues: unknown[];

  /** The text value to display above this component */
  header?: string;

  /** Determines if the selection component should display a loading icon */
  isLoading?: boolean;

  /** Display the label as required using a Form label */
  isRequired?: boolean;

  /** Removes the default top margin from the label */
  noGap?: boolean;

  /** An action to take when the selection menu closes */
  onCloseHandler?: (values?: unknown) => void;

  /** An action to take when the selection menu opens */
  onOpenHandler?: () => void;

  /** Optional. If specified, this text will override the views selection text displayed in the input after the custom-dropdown menu is closed. Example: Passing 'department' will display 'X department(s) selected`. Defaults to 'item' */
  selectionMessage?: string;

  /** Optional styles object. This component provides views styling. If you would like to override a style, specify the styling as a key value pair with the key being the internal component to style and the value being a styling object.*/
  styles?: StylesConfig<unknown, boolean, GroupBase<unknown>> | undefined;
}

/** Overrides the Placeholder component of the React-Select parent component.
 * Use this pattern to implement custom logic / behavior for any component which is a child of
 * the React-Select component. Leave outside the parent component to prevent unnecessary rendering */
const Placeholder = (
  props: PlaceholderProps,
  selectionMessage: string,
  selectedItemsRef: MutableRefObject<unknown[]>,
) => {
  const { placeholder } = props.selectProps;
  const selectedItemsCount = selectedItemsRef.current.length;
  let selectedMessage = '';

  // eslint-disable-next-line no-magic-numbers
  if (selectedItemsCount > 0) {
    // eslint-disable-next-line no-magic-numbers
    const plural = selectedItemsRef.current.length !== 1;

    selectedMessage = `${selectedItemsRef.current.length} ${
      plural ? `${selectionMessage}s` : `${selectionMessage}`
    } selected`;
  }

  return (
    <components.Placeholder {...props}>{selectedMessage.length ? selectedMessage : placeholder}</components.Placeholder>
  );
};

/** A custom, filterable, selection custom-dropdown wrapper for React-Select */
const FilterSelect = (props: FilterSelectProps): React.JSX.Element => {
  const {
    hasSelectAll,
    header,
    inputName,
    isLoading,
    isMultiSelect,
    isSingleTemplateSelected,
    isRequired,
    noGap = false,
    onChangeHandler,
    onCloseHandler,
    onOpenHandler,
    options,
    optionLabelKey,
    optionValueKey,
    placeHolderText,
    selectionMessage = 'item',
    selectedValues,
    styles,
  } = props;

  const { isOpen: isViewEditorOpen } = useAppSelector((state: RootState) => state.viewEditor.uiState);

  const optionsMappedToSelectFormat = React.useMemo(
    () =>
      options.map((option) => ({
        label: option[optionLabelKey as keyof typeof option],
        value: option[optionValueKey as keyof typeof option],
      })),
    [optionLabelKey, optionValueKey, options],
  );

  /** A mutable reference for the React-Select component.
   * Passed as a prop to the actual React-Select component and provides access to all internal state */
  const selectRef = React.useRef<SelectInstance<unknown> | null>(null);

  /** Allows the current options to be accessed via React-Select event handlers */
  const optionsRef = React.useRef(optionsMappedToSelectFormat);
  /** Keep the options ref current with each render */
  optionsRef.current = optionsMappedToSelectFormat;

  /** Allows the current selected values to be accessed via React-Select event handlers */
  const selectedValuesRef = React.useRef<unknown[]>(selectedValues);
  /** Keep the selected values ref current with each render*/
  selectedValuesRef.current = selectedValues;

  /** Unique token for select all option. No need to change this, hence the screaming snake-case */
  const SELECT_ALL_OPTION = {
    label: 'Select All',
    value: '<SELECT_ALL>',
  };

  // ToDo: Debounce
  /** React-Select onChange event wrapper and override
   * @param {unknown} _values - Unused as this wrapper turns React-Select into a controlled component
   * values can be referenced via the optionsRef and selectedValuesRef current values
   *
   * @param {ActionMeta<unknown>} metaAction - The React-Select action passed from the onChange event handler.
   * This is similar to a Redux dispatch and provides information such as the action type (option selected, deselected, etc)
   * and the option (the selected item value).
   * */
  const handleChange = (_values: unknown, metaAction: ActionMeta<unknown>) => {
    const { action, option } = metaAction;

    const optionValue = option && (option as { value: unknown }).value;

    switch (action) {
      case 'select-option': {
        // If select all was clicked, pass all available options (excluding select all) to the change handler
        if (option && optionValue === SELECT_ALL_OPTION.value) {
          onChangeHandler(optionsRef.current, action as DropdownAction);

          return;
        }

        const selectedValues = selectedValuesRef.current;
        // If an option other than select all was clicked AND we have more than one selected value, pass all selected values
        // and the newly selected option to the change handler
        const updatedItems = selectedValues.length
          ? optionsRef.current.filter(({ value }) => selectedValues.includes(value))
          : [];

        onChangeHandler([...updatedItems, option], action as DropdownAction);

        return;
      }
      case 'deselect-option': {
        if (isSingleTemplateSelected && isViewEditorOpen) return;
        // If select all was clicked, pass an empty array to the change handler, effectively
        // deselecting all items
        if (option && optionValue === SELECT_ALL_OPTION.value) {
          onChangeHandler([], action as DropdownAction);

          return;
        }

        // eslint-disable-next-line no-magic-numbers
        if (selectedValuesRef.current.length === 1) {
          return onChangeHandler([], action as DropdownAction);
        }
        // If an option other than select all was clicked, pass all selected options which are not
        // the deselected item to the change handler
        const remainingItems = optionsRef.current.filter(
          (item) => item.value !== optionValue && selectedValuesRef.current.includes(item.value),
        );

        onChangeHandler(remainingItems, action as DropdownAction);

        return;
      }

      case 'clear':
        if (isSingleTemplateSelected && isViewEditorOpen) return;
        // If the user selected clear (the X inside the input), remove all items
        onChangeHandler([], action as DropdownAction);
    }
  };

  const areAllItemsSelected = () => optionsRef.current.length === selectedValuesRef.current.length;

  /** Overrides React-Select logic to determine if an option is selected. This functions fires after the
   * onChange event handler and will therefore be provided with the most current selected values */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isOptionSelected = (option: unknown, _selectValue: Options<unknown>) => {
    // If select all was clicked, highlight all options
    if (areAllItemsSelected()) return true;

    const optionValue = option && (option as { value: unknown }).value;

    // Otherwise, if the selected value is included in the selected items, mark the item as selected
    return selectedValuesRef.current.includes(optionValue);
  };

  /** ToDo:
   * React-Select internally evaluates options in an array index like manner. This means that if an option has a
   * numerical value of 0, it will be evaluated as a falsy value and therefore not register as selected. Due to this,
   * since we, in our infinite wisdom, decided to use 0 as a value for a freaking department id, we would need to explicitly
   * check for a numerical 0 value, pass it as a string value, and then convert it back by parsing it as an integer.
   *
   * I am not doing this nonsense right now, so if a single option is selected with a value of 0, then the clear indicator
   * will not render in the input and the user will have to manually deselect the option.
   *
   * Read more here:
   * https://github.com/JedWatson/react-select/issues/1151
   *
   * #ItsNotABugItsAFeature, but like, actually
   */

  const gap = noGap ? DEFAULT_ZERO : DEFAULT_GAP_VALUE;

  return (
    <Box zIndex={DEFAULT_Z_INDEX}>
      <Stack direction={'column'} gap={gap}>
        {isRequired ? <FormLabel lineHeight={'8px'}>{header}</FormLabel> : <Text align={'left'}>{header}</Text>}
        <Select
          value={selectedValuesRef.current}
          defaultMenuIsOpen={false}
          tabIndex={0}
          autoFocus={false}
          tabSelectsValue={false}
          backspaceRemovesValue={false}
          escapeClearsValue={false}
          ref={selectRef}
          isDisabled={isLoading}
          isLoading={isLoading}
          isOptionSelected={isOptionSelected}
          isMulti={isMultiSelect}
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
          openMenuOnFocus={true}
          closeMenuOnSelect={false}
          blurInputOnSelect={false}
          placeholder={placeHolderText}
          captureMenuScroll={true}
          isSearchable={true}
          // ToDo: What is this?
          components={{ Placeholder: (props) => Placeholder(props, selectionMessage, selectedValuesRef) }}
          name={inputName}
          options={hasSelectAll ? [SELECT_ALL_OPTION, ...optionsRef.current] : optionsRef.current}
          className={'basic-multi-select'}
          classNamePrefix={'select'}
          styles={{
            control: (styles) => ({
              ...styles,
              maxWidth: '250px',
              minWidth: '250px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }),
            option: (styles, { isSelected }) => ({
              ...styles,
              backgroundColor: isSelected ? '#2b6cb0 !important' : 'transparent',
              fontSize: '14px',
              maxWidth: '250px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }),
            placeholder: (styles) => ({
              ...styles,
              textAlign: 'left',
            }),
            ...styles,
          }}
          onChange={handleChange}
          onMenuClose={onCloseHandler}
          onMenuOpen={onOpenHandler}
        />
      </Stack>
    </Box>
  );
};

export default FilterSelect;
