import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import type { AppDispatch, RootState } from './store';

// Use these hooks instead of useDispatch and useSelector
// The reason for this is to ensure the correct store types are being
// used for Dispatch() as the default Dispatch() type is unaware of thunks

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// This is a test
